import React from 'react';

import ServiceConnection from './ServiceConnection';
import klaviyoLogo from '../../assets/images/klaviyo-logo.png';
import ometriaLogo from '../../assets/images/ometria-logo.png';
import rechargeLogo from '../../assets/images/recharge-logo.png';
import shopifyLogo from '../../assets/images/shopify-logo.png';
import {
  SHOPIFY_CONFLUENCE,
  KLAVIYO_CONFLUENCE,
  OMETRIA_API_KEY_DOC,
  RECHARGE_API_DOC,
} from '../../config';

const accounts = [
  {
    name: 'shopify',
    logo: shopifyLogo,
    document: SHOPIFY_CONFLUENCE,
    text: 'Shopify store',
    formFields: [
      {
        name: 'url',
        label: 'Store URL',
        type: 'text',
        placeholder: 'Enter the Store URL with .myshopify.com',
      },
      {
        name: 'token',
        label: 'Admin API Access token',
        type: 'text',
        placeholder: 'e.g shpat_4324221eef7b44c7cjd89d2c7hs7U55',
      },
      {
        name: 'key',
        label: 'API Key',
        type: 'text',
        placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
      },
    ],
  },
  {
    name: 'klaviyo',
    logo: klaviyoLogo,
    document: KLAVIYO_CONFLUENCE,
    text: 'Klaviyo account',
    formFields: [
      {
        name: 'api',
        label: 'API Key',
        type: 'text',
        placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
      },
    ],
  },
  {
    name: 'ometria',
    logo: ometriaLogo,
    document: OMETRIA_API_KEY_DOC,
    text: 'Ometria account',
    formFields: [
      {
        name: 'apiTitle',
        label: 'API Key Title',
        type: 'text',
        placeholder: 'API key title configured on your account',
      },
      {
        name: 'api',
        label: 'API Key',
        type: 'text',
        placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
      },
    ],
  },
  {
    name: 'recharge',
    logo: rechargeLogo,
    document: RECHARGE_API_DOC,
    text: 'Recharge account',
    formFields: [
      {
        name: 'token',
        label: 'API Token',
        type: 'text',
        placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
      },
    ],
  },
];

function ConnectKeys() {
  return (
    <>
      {accounts.map((acc, i) => <ServiceConnection key={i} {...acc} ></ServiceConnection>)}
    </>
  );
}

export default ConnectKeys;

import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Error.module.scss';
import paperPlane from '../../assets/images/paperplane.png';

function Error() {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.errorContent}>
          <h1 className={styles.heading}>Oops</h1>
          <p>The page you are looking for could not be found</p>
          <Link to={'/'} className={styles.underline}>
            <p>Fly back Home<span><img className={styles.logo} src={paperPlane} alt="Paper Plane Icon" /></span></p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error;

import React, { useState, useEffect } from 'react';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './Upload.module.scss';
import ListComponent from '../../components/ListComponent/ListComponent';
import Spinner from '../../components/Spinner/Spinner';
import PdfService from '../../services/PdfService';


function CompanyUploads() {
  const { accountId } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const columns = ['fileName', 'dateUploaded', 'fileSize'];

  const loadData = async () => {
    setLoading(true);
    const getData = await PdfService.getCompanyPdfList(accountId);

    if (getData.success) {
      setData(getData.data);
    } else {
      toast.error(getData.error.error);
      history.push('/upload');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const searchKeys = ['fileName'];

  return (<>
    {loading ? <Spinner loading={loading} /> :
      <>
        <div className={styles.tableHeader}>
          <h1 className={styles.heading}> 
            <Link className={styles.backButton} to="/upload">Uploads</Link> <FontAwesomeIcon icon={faChevronRight} /> {data.companyName} PDFs</h1>
        </div>
        <div style={{ overflow: 'auto', height: '80vh' }}>
          <ListComponent 
            columns={columns}
            data={data.pdfFiles}
            searchKeys={searchKeys}
          />
        </div>
      </>}
  </>);
}

export default CompanyUploads;

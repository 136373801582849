import React, { useEffect, useState } from 'react';

import CurrencyList from 'currency-list';
import timezones from 'timezones-list';

import ModalForm from '../Modal/ModalForm';

function CompanyDetailsForm({ popUpOpen, setPopUpOpen, formTitle, onSubmit, placeholder }) {
  const {name, urls, excludedIpAddresses, timezone,
    currency, excludeParameters, disableVisitorLogs, isTechIntegrated} =
    placeholder || {};
  const [timezoneList, setTimezoneList] = useState(null);
  const [currencies, setCurrencies] = useState(null);

  const getTimezoneList = async () => {
    const timezonesObj = timezones.map((timezone) => {
      return {label: timezone.label, value: `${timezone.label.split(' ')[0]}`};
    });
    setTimezoneList(timezonesObj);
  };
  const getCurrencies = async () => {
    const currencyObject = CurrencyList.getAll('en_GB');
    const currencyArray = Object.entries(currencyObject).map(([code, currency]) => {
      return { label: `${currency.name} - ${code}`, value: code };
    });
    setCurrencies(currencyArray);
  };

  useEffect(() => {
    getCurrencies();
    getTimezoneList();
  }, []);

  return (
    <ModalForm
      isOpen={popUpOpen}
      onClose={() => setPopUpOpen(false)}
      title={formTitle}
      onSubmit={onSubmit}
      config={[
        {
          name: 'companyName',
          label: 'Company Name',
          restricted: name,
          type: 'text',
          validation: {
            required: 'Empty!',
            pattern: {
              value: /^[^\d\s]{2,150}$/,
              message: 'Company Name length is not valid.',
            },
          },
        },
        {
          name: 'urls',
          label: 'URL',
          defaultValue: urls ? urls[0] : '',
          type: 'text',
          validation: {
            required: 'Empty!',
            pattern: {
              value: /^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}([\/?].*)?$/,
              message: 'URL format is not valid',
            },
          },
        },
        {
          name: 'excluded_ip_addresses',
          label: 'Exclude IP address',
          defaultValue: excludedIpAddresses ? excludedIpAddresses[0] : '',
          type: 'text',
          validation: {
            required: 'Empty!',
            pattern: {
              value: new RegExp(
                  `^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.)` + `{3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$`,
              ),
              message: 'Enter a valid IP!',
            },
          },
        },
        {
          name: 'timezone',
          label: 'Timezone',
          type: 'dropdown',
          dropdownValues: timezoneList ?? [''],
          selected: timezone,
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'dropdown',
          dropdownValues: currencies ?? [''],
          selected: currency,
        },
        {
          name: 'exclude_parameters',
          label: 'Exclude Parameters',
          toggled: {default: excludeParameters},
        },
        {
          name: 'disable_visitor_logs',
          label: 'Turn off visitor logs and profile',
          toggled: {default: disableVisitorLogs},
        },
        {
          name: 'is_tech_integrated',
          label: 'Access to dashboard',
          toggled: {default: isTechIntegrated},
        },
      ]}
    />
  );
}

export default CompanyDetailsForm;

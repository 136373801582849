import React from 'react';

import { Route } from 'react-router-dom';

import Error from '../../pages/Error/Error';

export default function ProtectedRoute({ component: Component, condition, ...rest}) {
  return <Route {...rest} render={(props) => condition ? (
      <Component {...props} />
    ) : (
      <Error />
    )
  }
  />;
};

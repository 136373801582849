import React, { useState, useEffect } from 'react';

import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ModalForm from './ModalForm';
import styles from './ModalMultipartForm.module.scss';
import Spinner from '../Spinner/Spinner';

const MultipartStepIndicator = ({ currentStep, stepLabels} ) => {
  return (<ul className={styles.stepIndicator}>
    {[...Array(stepLabels.length).keys()].map((step, index) => {
      return (<li key={step} className={`${styles.step} ${
        step >= currentStep ? styles.greyLine : ''}`}>
        <FontAwesomeIcon
          icon={step < currentStep ? faCircleCheck : faCircle}
          color={step - 1 < currentStep ? '#5b9bd4' : '#959595'}
          size='xl'
        />
        {step >= currentStep && <span className={styles.stepNumber}>{step + 1}</span>}
        {/* Start from 1, not 0 */}
        <div className={styles.stepLabel}>{stepLabels[index]}</div>
      </li>);
    })}
  </ul>);
};

const ModalMultipartForm = ({ isOpen, title, onSubmit, onClose,
  stepLabels, formConfigs, onSave, setFormData, indexFormPageOpen, setIndexFormPageOpen }) => {
  // const [indexFormPageOpen, setIndexFormPageOpen] = useState();
  const [formsDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const formCount = formConfigs.length;

  const renderStepIndicator = () => {
    if (!stepLabels) return;
    for (const config of formConfigs) {
      const stepAssignedIndex = stepLabels.indexOf(config.step);
      console.assert(stepAssignedIndex >= 0);

      // Workaround to work when rerendered
      if (typeof config.description == 'string') {
        config.descriptionString = config.description;
      }
      config.description = (<div>
        <MultipartStepIndicator
          currentStep={stepAssignedIndex}
          stepLabels={stepLabels}
        />
        <br/>
        {config.descriptionString}
      </div>);
    }
  };
  renderStepIndicator();

  const updateFormData = (data) => {
    formsDataArray[indexFormPageOpen] = data;
    const updatedFormData = Object.assign({}, ...formsDataArray);
    setFormData(updatedFormData);
    // This is needed in some places because formData is in
    // campaign list and doesnt refresh instantly
    return updatedFormData;
  };

  const goToPage = (pageNum, data) => {
    updateFormData(data);
    if (0 < pageNum < formCount) {
      setIndexFormPageOpen(pageNum);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    setIndexFormPageOpen(isOpen ? 0 : null);
    if (formConfigs.length > 0) renderStepIndicator();
  }, [isOpen]);

  return (<div className={styles.modalMultipartForm}>
    {formConfigs.map((formConfig, index) => {
      return (
        <ModalForm
          key={index}
          title={title}
          isOpen={index === indexFormPageOpen}
          onSubmit={index === formCount - 1 ? (data) => {
            // Not invoking update as a workaround for double form reload
            const updatedFormData = updateFormData(data);
            onSubmit(updatedFormData);
          } : (data) => {
            if ('execute' in formConfig && 'execArgsFormKeys' in formConfig) {
              const updatedFormData = updateFormData(data);
              setLoading(true);
              const res = formConfig.execute(
                  ...formConfig.execArgsFormKeys.map((key) => updatedFormData[key]),
              );
              res.then((result) => {
                if (result !== false) goToPage(index + 1, data);
                setLoading(false);
              });
            } else goToPage(index + 1, data);
            localStorage.setItem('campaignDraft',
                JSON.stringify(Object.assign({}, ...formsDataArray)));
          }
          }
          // leftButtonText='SAVE AS DRAFT'
          leftButtonFunction={(data) => {
            updateFormData(data);
            onSave(formData);
            onClose();
          }}
          submitButtonText='NEXT'
          onClose={index > 0 ? () => goToPage(index - 1, {}) : () => {
            onClose();
            localStorage.removeItem('campaignDraft');
          }}
          closeButtonText={index > 0 ? 'PREVIOUS' : 'CANCEL'}
          {...formConfig}
        >
          {formConfig.hasOwnProperty('content') && formConfig.content}
          <Spinner loading={loading} />
        </ ModalForm>
      );
    })}
  </div>);
};

export { MultipartStepIndicator };
export default ModalMultipartForm;

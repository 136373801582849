// import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import React, { useEffect, useState } from 'react';

import styles from './Dashboard.module.scss';
import Spinner from '../../components/Spinner/Spinner';
import QuicksightService from '../../services/QuicksightService';


function Dashboard() {
  const [dashboardLink, setDashboardLink] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const loadDashboard = async () => {
    setLoading(true);
    const response = await QuicksightService.getDashboardUrl();

    if (response.success) {
      setDashboardLink(response.data.url);
    } else {
      console.error('Error fetching quicksight dashboard:', response.error);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadDashboard();
  }, []);

  if (error) {
    return <>Error loading dashboard.</>;
  }

  return (
    <>
      {!loading && <iframe src={dashboardLink} className={styles.iframeEmbedding} />}
      <Spinner loading={loading} />
    </>
  );
}

export default Dashboard;

// format_campaigns.js

const formatCampaignToFlat = (response) => {
  const data = response.data;
  if (data.length == 0) {
    return response;
  }
  response.data = data.map((item) => ({
    id: item.id,
    campaignType: item.campaignType,
    dataSource: item.dataSource,
    company: item.company,
    companyIdsite: item.companyIdsite,
    campaignName: item.campaignName,
    listId: item.listId,
    status: item.status,
    suppressionPeriod: item.suppressionPeriod,
    controlSplit: item.controlSplit,
    dailySendLimit: item.dailySendLimit,
    activationTime: item.activationTime,
    aBSplit: item.variants[0]['percentageSplit'],
    variantAName: item.variants[0]['variantName'],
    letterPathA: item.variants[0]['letterPath'],
    envelopePathA: item.variants[0]['envelopePath'],
    variantBName: item.variants[1] ? item.variants[1]['variantName'] : '',
    letterPathB: item.variants[1] ? item.variants[1]['letterPath'] : '',
    envelopePathB: item.variants[1] ? item.variants[1]['envelopePath'] : '',
    // costPerSend: item.costPerSend / 100,
  }));
  // console.log(response);
  return response;
};

const formatCampaignToNested = (data) => ({
  ...data,
  // Update activation time in case the summary was open for longer than 5 minutes
  activation_time: new Date(new Date().getTime() + 5 * 60000).toISOString().slice(11, 16),
  // cost_per_send: data.cost_per_send ? data.cost_per_send*100 : 85,
  cost_per_send: 85,
  variants: data.a_b_split && data.a_b_split < 100 ? [
    {
      variant_name: data.variant_a_name,
      letter_path: data.letter_path_a,
      envelope_path: data.envelope_path_a,
      percentage_split: data.a_b_split,
    },
    {
      variant_name: data.variant_b_name,
      letter_path: data.letter_path_b,
      envelope_path: data.envelope_path_b,
      percentage_split: 100 - data.a_b_split,
    },
  ] : [
    {
      variant_name: data.campaign_name,
      letter_path: data.letter_path_a,
      envelope_path: data.envelope_path_a,
      percentage_split: 100,
    },
  ],
});

export { formatCampaignToFlat, formatCampaignToNested };

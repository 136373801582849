import React, { useState, useEffect } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';

import './MenuButton.scss'; // Import the SCSS file
import ABSplitField from '../../components/Inputs/ABSplitField';
import { usePermissions } from '../../hooks/usePermissions';
import CreateCampaignConfirmation from '../../pages/CampaignList/CreateCampaignConfirmation';
import CampaignService from '../../services/CampaignService';
import CompanyService from '../../services/CompanyService';
import ModalForm from '../Modal/ModalForm';

function CampaignMenuButton({ campaignData, campaignId, refresh }) {
  // TODO: letter/envelope B ?
  const data = convertKeysToSnakeCase(campaignData);
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [editLetterPopUpOpen, setEditLetterPopUpOpen] = useState(false);
  const [editEnvelopePopUpOpen, setEditEnvelopePopUpOpen] = useState(false);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [changeStatusPopUpOpen, setChangeStatusPopUpOpen] = useState(false);
  const [letterPaths, setLetterPaths] = useState([]);
  const [envelopePaths, setEnvelopePaths] = useState([]);
  const [confirmAddCampaignFormOpen, setConfirmAddCampaignFormOpen] = useState(false);
  const [lastOpenedModal, setLastOpenedModal] = useState('');
  const [formData, setFormData] = useState({});
  const [clickYAtBottom, setClickYAtBottom] = useState(false);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';

  function convertKeysToSnakeCase(obj) {
    const snakeCaseObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
        snakeCaseObj[snakeCaseKey] = obj[key];
      }
    }
    return snakeCaseObj;
  }

  const loadLetterPaths = async (companyId) => {
    const getLetterPaths = await CompanyService.getCompanyLetterPaths(companyId);

    if (getLetterPaths.success) {
      setLetterPaths(getLetterPaths.data.map((letterPath) => {
        return { label: letterPath, value: letterPath };
      }));
    } else {
      toast.error('Failed to load company letter templates. Please try again');
    }
  };

  const loadEnvelopePaths = async (companyId) => {
    const getEnvelopePaths = await CompanyService.getCompanyEnvelopePaths(companyId);

    if (getEnvelopePaths.success) {
      setEnvelopePaths(getEnvelopePaths.data.map((envelopePath) => {
        return { label: envelopePath, value: envelopePath };
      }));
    } else {
      toast.error('Failed to load company envelope templates. Please try again');
    }
  };

  const responseHandling = (response, setPopUpOpen) => {
    if (response.success) {
      setPopUpOpen(false);
      refresh();
    } else {
      if (response.error && response.error.errors) {
        const errorMessages = response.error.errors.map((err) => err.detail).join('\n');
        toast.error(errorMessages);
      }
    }
  };

  const editCampaign = async (data) => {
    try {
      const response = await CampaignService.editCampaign(campaignId, data);
      responseHandling(response, setEditPopUpOpen);
    } catch (e) {
      toast.error('An error occured editing selected campaign');
    }
  };

  const deleteCampaign = async () => {
    try {
      const response = await CampaignService.deleteCampaign(campaignId);
      responseHandling(response, setDeletePopUpOpen);
    } catch (e) {
      toast.error('An error occured deleting selected campaign');
    }
  };
  const changeCampaignStatus = async (status) => {
    try {
      const response = await CampaignService.changeCampaignStatus(campaignId, status);
      responseHandling(response, setChangeStatusPopUpOpen);
    } catch (e) {
      toast.error('An error occured changing selected campaign\'s status');
    }
  };

  const handleMenuButtonClick = (event) => {
    if (event.clientY - 60 > window.innerHeight / 2) setClickYAtBottom(true);
    else setClickYAtBottom(false);
  };

  useEffect(() => {
    loadLetterPaths(data.company_idsite);
    loadEnvelopePaths(data.company_idsite);
  }, []);

  return (
    <div className="menu-container">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button className="menu-button" onClick={handleMenuButtonClick}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Menu.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {isAdmin && <Menu.Items className={`menu-items
              ${clickYAtBottom ? 'menu-items-bottom' : ''}`}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setEditPopUpOpen(true)}
                    >Edit Campaign</button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setEditLetterPopUpOpen(true)}
                    >Edit Letter Creative</button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setEditEnvelopePopUpOpen(true)}
                    >Edit Outer Envelope Creative</button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setChangeStatusPopUpOpen(true)}
                    >{`${data.status === 'Active' ? 'Pause' : 'Reactive'} Campaign`}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setDeletePopUpOpen(true)}
                    >
                      <span className='delete-button'>Delete Campaign</span>
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>}
            </Transition>
          </>
        )}
      </Menu>
      <ModalForm
        isOpen={editPopUpOpen}
        onClose={() => setEditPopUpOpen(false)}
        title='Edit Campaign'
        onSubmit={(submissionData) => {
          // Override these values in the Edit form
          const keysToDefault = ['control_split', 'daily_send_limit', 'suppression_period'];
          for (const key of keysToDefault) data[key] = 0;
          setFormData({...data, ...submissionData, ...{company: data.company}});
          setLastOpenedModal('mainEdit');
          setEditPopUpOpen(false);
          setConfirmAddCampaignFormOpen(true);
        }}
        submitButtonText='NEXT'
        config={[
          {
            name: 'company',
            label: 'Company',
            type: 'dropdown',
            dropdownValues: [
              {label: data.company, value: ''}, // No value as this isnt send in patch request
            ],
            disabled: true,
          },
          {
            name: 'campaign_name',
            label: 'Campaign Name',
            defaultValue: data.campaign_name,
            type: 'text',
            placeholder: 'Name for the campaign',
            validation: {
              required: 'Empty!',
            },
          },
          {
            name: 'list_id',
            label: 'Segment ID',
            restricted: data.list_id,
            type: 'text',
            placeholder: 'Klaviyo Segment ID',
            validation: {
              required: 'Empty!',
            },
          },
          data.a_b_split !== 100 ? {
            type: 'custom',
            component: <ABSplitField
              defaultSplit={data.a_b_split}
              variantAName={data.variant_a_name}
              variantBName={data.variant_b_name}
            />,
          } : null,
          /* {
            name: 'cost_per_send',
            label: 'Cost Per Send',
            type: 'decimal',
            unit: '£',
            toggled: {default: data.cost_per_send ? true : false},
            defaultValue: data.cost_per_send ?? 0.85,
            min: 0,
            validation: {
              min: 0,
            },
          }, */
          {
            name: 'suppression_period',
            label: 'Suppression period',
            type: 'number',
            unit: 'days',
            toggled: {default: data.suppression_period ? true : false},
            defaultValue: data.suppression_period ?? 0,
            min: 0,
            max: 365,
            validation: {
              min: 0,
              max: 365,
            },
          },
          {
            name: 'control_split',
            label: 'Control split',
            type: 'number',
            unit: '%',
            toggled: {default: data.control_split ? true : false},
            defaultValue: data.control_split ?? 0,
            min: 0,
            max: 100,
            validation: {
              min: 0,
              max: 100,
            },
          },
          {
            name: 'daily_send_limit',
            label: 'Daily Send Limit',
            type: 'number',
            unit: 'mails',
            toggled: {default: data.daily_send_limit ? true : false},
            defaultValue: data.daily_send_limit ?? 0,
            min: 0,
            validation: {
              min: 0,
            },
          },
        ].filter(Boolean)}
      />
      <ModalForm
        isOpen={editLetterPopUpOpen}
        onClose={() => setEditLetterPopUpOpen(false)}
        title={'Edit Letter Creative'}
        onSubmit={(submissionData) => {
          setFormData({...data, ...submissionData, ...{company: data.company}});
          setLastOpenedModal('letter');
          setEditLetterPopUpOpen(false);
          setConfirmAddCampaignFormOpen(true);
        }}
        submitButtonText='NEXT'
        config={[
          {
            label: data.a_b_split !== 100 ? `Letter ${data.variant_a_name}` : '',
            name: 'letter_path_a',
            type: 'dropdown',
            dropdownValues: letterPaths,
            selected: data.letter_path_a,
            validation: {
              required: 'Empty',
            },
          },
          data.a_b_split !== 100 ? {
            label: `Letter ${data.variant_b_name}`,
            name: 'letter_path_b',
            type: 'dropdown',
            dropdownValues: letterPaths,
            selected: data.letter_path_b,
            validation: {
              required: 'Empty',
            },
          } : null,
        ].filter(Boolean)}
      />
      <ModalForm
        isOpen={editEnvelopePopUpOpen}
        onClose={() => setEditEnvelopePopUpOpen(false)}
        title={'Edit Outer Envelope Creative'}
        onSubmit={(submissionData) => {
          setFormData({...data, ...submissionData, ...{company: data.company}});
          setLastOpenedModal('envelope');
          setEditEnvelopePopUpOpen(false);
          setConfirmAddCampaignFormOpen(true);
        }}
        submitButtonText='NEXT'
        config={[
          {
            label: data.a_b_split !== 100 ? `Envelope ${data.variant_a_name}` : '',
            name: 'envelope_path_a',
            type: 'dropdown',
            dropdownValues: envelopePaths,
            selected: data.envelope_path_a,
            validation: {
              required: 'Empty',
            },
          },
          data.a_b_split !== 100 ? {
            label: `Envelope ${data.variant_b_name}`,
            name: 'envelope_path_b',
            type: 'dropdown',
            dropdownValues: envelopePaths,
            selected: data.envelope_path_b,
            validation: {
              required: 'Empty!',
            },
          } : null,
        ].filter(Boolean)}
      />
      <ModalForm
        isOpen={changeStatusPopUpOpen}
        onClose={() => setChangeStatusPopUpOpen(false)}
        title={`${data.status === 'Active' ? 'Pause' : 'Reactive'} Campaign`}
        description={'Are you sure?'}
        onSubmit={() => {
          const updatedData = {
            ...data,
            status: data.status === 'Active' ? 'Inactive' : 'Active',
          };
          changeCampaignStatus(updatedData);
        }}
      />
      <ModalForm
        isOpen={deletePopUpOpen}
        onClose={() => setDeletePopUpOpen(false)}
        title='Delete Campaign?'
        description={'This action cannot be reversed. If you are absolutely ' + 'sure please type "permanently delete" in the box.'}
        onSubmit={() => deleteCampaign()}
        config={[
          {
            name: 'permanently-delete',
            label: 'Are you sure?',
            placeholder: 'permanently delete',
            type: 'text',
            validation: {
              required: 'Empty!',
              pattern: {
                value: /^permanently delete$/,
                message: 'Invalid!',
              },
            },
          },
        ]}
      />
      <CreateCampaignConfirmation
        isOpen={confirmAddCampaignFormOpen}
        onClose={() => {
          if (lastOpenedModal === 'mainEdit') setEditPopUpOpen(true);
          else if (lastOpenedModal === 'letter') setEditLetterPopUpOpen(true);
          else if (lastOpenedModal === 'envelope') setEditEnvelopePopUpOpen(true);
          setConfirmAddCampaignFormOpen(false);
        }}
        onConfirm={() => {
          editCampaign(formData);
        }}
        data={formData}
      />
    </div>
  );
}

export default CampaignMenuButton;
